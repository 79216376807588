<template>
    <div>
        <LoadingSpinner v-if="loading" class="vh-50" />
        <LoadingError v-else-if="error" class="vh-50" />
        <router-view v-else :payout-reports="payoutReports" />
    </div>
</template>

<script>
    import { formatISODate } from '../filters/date';
    import { getPayoutReports } from '@/services/PayoutReportService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';

    export default {
        name: 'PayoutReports',
        components: {
            LoadingSpinner,
            LoadingError,
        },
        data() {
            return {
                loading: true,
                error: null,
                payoutReports: null,
            };
        },
        async mounted() {
            try {
                const { data } = await getPayoutReports();
                data.forEach(payout => {
                    payout._created_str = formatISODate(payout.created, 'Pp');
                    payout._batch_effective_date_str = formatISODate(payout.batch_effective_date, 'P');
                });
                this.payoutReports = data;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
    };
</script>
